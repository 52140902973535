export const LoadingState =
    Object.freeze({
        notStarted: 0,
        pending: 1,
        success: 2,
        failure: 3,
    });
export const ApiTypes =
    Object.freeze({
        key: 'key',
        user: 'user',
    });
export const QuestionType =
    Object.freeze({
        smileyButton: 1,
        smileyButtonWithCommentForAllChoices: 7,
        checkbox: 2,
        shortText: 3,
        longText: 4,
        eNPS: 8,
        yesNo: 9,
        radio: 10,
        hME: 11,
        hMEWithCommentForAllChoices: 13,
        numberedWithChoiceValue: 12,
        instructionWithoutChoices: 99,
    });
export const QuestionTypeThatTriggerSuggestions = Object.freeze([
    QuestionType.smileyButton,
    QuestionType.smileyButtonWithCommentForAllChoices,
    QuestionType.hME,
    QuestionType.hMEWithCommentForAllChoices,
    QuestionType.numberedWithChoiceValue,
    QuestionType.eNPS,
]);
export const CommentType =
    Object.freeze({
        notApplicable: 0,
        none: 1,
        optional: 2,
        required: 3,
    });
export const TextInputLength =
    Object.freeze({
        shortTextLength: 200,
        longTextLength: 1500,
        commentTextLength: 1500,
    });
export const SurveyAnswerStatus =
    Object.freeze({
        notAccessed: 0,
        accessed: 1,
        partiallyAnswered: 2,
        completed: 3,
    });
export const Environments =
    Object.freeze({
        local: 'local',
        dev: 'dev',
        staging: 'staging',
        prod: 'prod',
    });

export const PiggyBackTarget =
    Object.freeze({
        Company: 0,
        Division: 1,
        Team: 2,
        Label: 3,
        Organization: 4,
    });
